import React from 'react';
import { Box, Container, List, styled, Typography } from "@mui/material";
const GuideList = () => {
  return (
    <Box sx={{mt : 2 }}>
       <Typography variant= "h5" sx={{fontWeight  : "bold", textAlign : "center", mb : 2,textDecoration:"underline"}} >8 Step Guide to Buying a New House in India</Typography>
      <ol>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Budget:</Typography> Plan your budget to know how much money you can afford to spend each month.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Location:</Typography>Choose a location that suits your needs and has good resale value.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Rental rates in the area:</Typography> If you're planning to invest in a rental property, consider the rental rates in the area.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Good resale value: </Typography>Consider the resale value of the property before buying.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Loan eligibility: </Typography> Check your loan eligibility before applying for a home loan.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Stamp duty & registration fee:</Typography> Know the rates and charges applicable in your city before buying.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Additional costs: </Typography>Consider the fees or service charges by real estate agents, lenders, home inspector, lawyer or notary, and maintenance costs.</li>
        <li><Typography sx={{fontWeight : "bold" , fontSize : "1.1rem"}}>Property insurance: </Typography>Property insurance: Protect your property with the right home insurance policy.</li>
      </ol>
    </Box>
  );
};

export default GuideList;
