import React from 'react'
import Hero from './Hero'
import Companies from './Companies'
import Guide from './Guide'
import Properties from './Properties'
import Details from './Details'
import GetStarted from './GetStarted'

const Home = () => {
  return (
    <> <Hero />
    <Companies />
    <Guide />
    <Properties />
    <Details />
    <GetStarted />
   </>
  )
}

export default Home