import React from 'react'
import Navbar from './Navbar'
import { Box, Container, List, styled, Typography } from "@mui/material";
import { minHeight, textAlign } from '@mui/system';
import GuideList from './GuideList';
import GuideFaq from './GuideFaq';
import GuideDetails from "./GuideDetails"
const BuyingGuid = () => {
 

  const PropertiesTextBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }));
  return (
    <Box sx={{backgroundColor: "#E6F0FF"}}>
     <Navbar hideLinks={true}/>
   
     <Box sx={{px:15 , backgroundColor : "white" , width : "100vw" , mt:"-2vh", pt:"2vh"}}>
     <GuideList/>
     </Box>
     <Box sx={{px:15, backgroundColor : "white", pt: "4vh"}} > 
      <Box sx={{backgroundColor:"#E9FBE9", minHeight :"70vh" }}><GuideDetails/></Box>
      
     </Box>
     <Box sx={{px:15, backgroundColor : "white", pt: "4vh"}} > 
      <GuideFaq/>

     </Box>
     
    </Box>
  )
}

export default BuyingGuid