import Companies from "./Components/Companies";
import Guide from "./Components/Guide";
import Hero from "./Components/Hero";
import Properties from "./Components/Properties";
import Details from "./Components/Details";
import GetStarted from "./Components/GetStarted";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import BuyingGuid from "./Components/BuyingGuid"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
function App() {
  return (
    <Router>
    <>
    <Switch>
        
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/buyingGuide" >
              <BuyingGuid/>
          </Route>
      </Switch>
     
        <Footer />
    </>
    </Router>
  );
}

export default App;
