import React from 'react'
import Faq from "react-faq-component";
const data = {
    title: "FAQ'S",
    rows: [
        {
            title: "How to choose the right property?",
            content: `One should buy property in an area which has adequate basic amenities such as power, water, sewerage, etc. It is important to do your checks and balances while deciding on a project. Infrastructure in the area, connectivity, builder's goodwill and price of the property are key components a buyer needs to take into consideration. A buyer should also carefully check the builder's experience, number of projects completed and delivered, banking institutions involved and present buy options available to suit your requirements. It is better you conduct a field survey before identifying a suitable property meeting your budget and location preference.`,
        },
        {
            title: "Should one invest in small cities?",
            content:
                "In small cities, the appreciation is usually less but so is the initial investment compared to the metros. However, with major infrastructure developments, cities like Indore, Coimbatore, Bhubaneshwar and a few others are witnessing growth in prices as well as returns. Always choose a city that has good economic drivers such as IT, ITeS or manufacturing hubs. This ensures continued user interest for re-sale when you want to exit an investment or for rental returns while you hold the property till it is well leveraged and gives good returns.",
        },
        {
            title: "What are the advantages in working with us?",
            content: `It is always advisable to go for a reputed agent and developer, especially for property which is under construction because it covers a great part of the risk. For built-up property, a bigger area in a reasonably good complex with a good clear title would definitely be a good buy. With a builder having market standing you can be assured of the title and delivery. You may end up paying slightly higher, but it is worth the peace of mind.`,
        },
        {
            title: "What is better buy or rent?",
            content: `There is a simple way of judging whether to buy a property or whether you should lease. If you find a house that you would like to stay in and which is close to your workplace or easily accessible from there, then buy it. But remember that the Equated Monthly Instalments (EMI) on your property should not be over 40 per cent of your monthly salary. That way you would be comfortable paying it back. You need 10-15 per cent of the cost as your personal contribution to the purchase, as banks do not lend 100 per cent.`,
        },{
            title : "What is the difference b/w LTCG and STCG?",
            content : `When a property is withheld by a person for more than three years, it results in Long Term Capital Gain (LTCG) on sale of that property, on which Capital Gain Tax can be saved by investing that money in a residential property.

            When a property is withheld by a person for less than 36 months, it results in Short Term Capital Gain (STCG) on which tax cannot be saved. STCG is added to the income of a person and tax is calculated according to the slab rates of the Income Tax.`
        }
    ],
};
const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};
const styles = {
    // bgColor: 'white',
    titleTextColor: "Black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};
const GuideFaq = () => {
  return (
    <div>
    <Faq
        data={data}
        styles={styles}
        config={config}
    />
</div>
  )
}

export default GuideFaq